import React, { useState, useRef, useContext, useEffect } from 'react'
import 'react-phone-input-2/lib/style.css'
import { useHistory } from 'react-router-dom'
import { postCheckoutCall, getSelectCall, getCall } from '../../api/axios'
import useGet from '../../hooks/useGet'
import useCancellablePromise from '../../api/cancelRequest'
import { ToastContext } from '../../context/toastContext'
import CustomizationRenderer from '../../components/application/product-list/product-details/CustomizationRenderer'
import { toast_actions, toast_types } from '../../components/shared/toast/utils/toast'
import { LocationContext } from '../../context/locationContext'
import { SSE_TIMEOUT } from '../../constants/sse-waiting-time'
import { AddCookie, getValueFromCookie } from '../../utils/cookies'
import NewAddressForm from '../OrderModal/newAddressForm'
import PlaceOrder from '../OrderModal/PlaceOrder'
import ProductImage from '../../assets/images/no_image_found.png'
import NoAddressIcon from '../../assets/svg/NoAddressIcon'
import DeleteLogo from '../../assets/svg/DeleteLogo'
import { FormControlLabel, LinearProgress, RadioGroup } from '@mui/material'
import Radio from '@mui/material/Radio'
import { deleteWithAuthentication } from '../../api/axios'
import {
  OrderModalWrapper,
  ProductDetailWrapper,
  ProductImageWrapper,
  ProductDetailContainer,
  ProductName,
  ProductPricingSection,
  ProductPrice,
  OriginalPrice,
  QuantityWrapper,
  QuantityOption,
  ProductQuantity,
  InformationSection,
  CartDetailContainer,
  CartSummaryWrapper,
  CartSummaryTitle,
  CartDetailWrapper,
  CartDetailTitle,
  CartDetailContent,
  PaymentModeWrapper,
  PaymentType,
  OrderTotalWrapper,
  TotalOrderContent,
  DeliveryAddressWrapper,
  DeliverAddressContainer,
  AddressText,
  AddressListWrapper,
  AddressListContainer,
  AddressDetailSection,
  IconWrapper,
  UserName,
  AddressDetail,
  Pincode,
  DeliveryMode,
  DeliveryAddressTitle,
  PaymentDetailContent,
  InfoDetailSection,
  DeliveryModeContainer,
  ErrorMessage,
  NewAddress,
  LoaderWrapper,
} from 'styles/orderModal'
import { getErrorMessageFromErrorCode } from 'helper/errorCode'
import { checkPolygonAvailability, checkInterCityHyperLocal } from 'utils/serviceableHelper'
import VariationsRenderer from 'components/application/product-list/product-details/VariationsRenderer'
import { formatIndianRupees, getOrCreateDeviceId, getUserId } from 'helper'
import { Loader } from 'styles/Loader'
import loader from 'assets/gif/loader.gif'

const PlaceOrderModal = ({ product, onClose, localQuantity: quantity }) => {
  const productId = product?.id
  const buyNow = true
  const [selectedAddressId, setSelectedAddressId] = useState()
  const [localQuantity, setLocalQuantity] = useState(quantity || 1)
  const [customization_state, setCustomizationState] = useState({})
  const [fullfillments, setFullfillments] = useState()
  const [productAmount, setProductAmount] = useState()
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const [initializeOrderLoading, setInitializeOrderLoading] = useState(false)
  const [placeOrderEnable, setPlaceOrderEnable] = useState(false)
  const [selectedFulfillment, setSelectedFulfillment] = useState(null)
  const [orderSummaryData, setOrderSummaryData] = useState()
  const [quantityChange, setQuantityChange] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [serviceable, setServiceable] = useState(null)
  const [selectedItemId, setSelectedItemId] = useState([{}])
  const { latitude, longitude } = useContext(LocationContext)
  const dispatch = useContext(ToastContext)
  const responseRef = useRef([])
  const eventTimeOutRef = useRef([])
  const updatedCartItems = useRef([])
  const history = useHistory()
  const getPinCode = localStorage.getItem('pinCode')
  const getCurrentPinCode = localStorage.getItem('pinCode')
  const { cancellablePromise } = useCancellablePromise()
  const [variationState, setVariationState] = useState([])
  const [transactioId, setTransactionId] = useState()
  const [productsDetails, setProductDetails] = useState({})
  const [variantId, setVariantId] = useState()
  const [uomProductId, setUomProductId] = useState()
  const [openForm, setOpenForm] = useState(false)
  const userId = getUserId()
  const [addressList, setAddressList] = useState([])
  const { data: deliveryAddressdata, refetch: fetchDeliveryAddress } = useGet(
    'delivery_address',
    `/clientApis/v1/delivery_address`,
  )

  const maxProductValue = parseFloat(product?.item_details?.price?.maximum_value || 0)
  const currentProductValue = parseFloat(product?.item_details?.price?.value || 0)
  const formattedMaxProductValue = maxProductValue.toFixed(2)
  const formattedCurrentProductValue = currentProductValue.toFixed(2)
  const getProductDetails = async (productId) => {
    try {
      const data = await cancellablePromise(
        getCall(
          `${process.env.REACT_APP_BASE_PROTOCOL_URL}/protocol/item-details?id=${variantId ? variantId : uomProductId ? uomProductId : productId}`,
        ),
      )

      setProductDetails(data)
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error?.response?.data?.error?.message,
        },
      })
    }
  }

  useEffect(() => {
    getProductDetails(productId)
    setQuantityChange(true)
  }, [productId, variantId, uomProductId])

  useEffect(() => {
    fetchDeliveryAddress()
    setAddressList(deliveryAddressdata)
  }, [deliveryAddressdata])

  const checkAvailabilityForCartItems = async (cartProductItems, latitude, longitude, setserviceableCardItemId) => {
    if (cartProductItems.length) {
      const providerTags = cartProductItems
        .flatMap((item) => item?.provider_details?.tags)
        .filter((tag) => tag?.code === 'serviceability')
      const commonCategoryIds = []
      cartProductItems.forEach((item) => {
        const categoryId = item?.item_details?.category_id
        if (
          categoryId &&
          providerTags.some((tag) => tag?.list[2]?.value === '13' && tag?.list[1]?.value === categoryId)
        ) {
          commonCategoryIds.push(categoryId)
          if (!setserviceableCardItemId) return // Exit early if setserviceableCardItemId is not provided
          setserviceableCardItemId(item?._id)
        } else if (
          categoryId &&
          providerTags.some((tag) => tag?.list[2]?.value === '11' && tag?.list[1]?.value === categoryId)
        ) {
          return
        } else if (
          categoryId &&
          providerTags.some((tag) => tag?.list[2]?.value === '10' && tag?.list[1]?.value === categoryId)
        ) {
          //hyperlocal
          const checkAva = checkInterCityHyperLocal(cartProductItems, categoryId, '10', latitude, longitude)
          setServiceable(checkAva)
          return
        } else {
          //Pan India local 12
          return
        }
      })
      await Promise.all(
        commonCategoryIds.map(async (categoryId) => {
          const checkAvailability = await checkPolygonAvailability(providerTags, categoryId, [latitude, longitude])
          setServiceable(checkAvailability)
        }),
      )
    }
  }

  useEffect(() => {
    checkAvailabilityForCartItems([productsDetails], latitude, longitude)
  }, [productsDetails, latitude, longitude])

  const updateQuantity = (increment) => {
    setQuantityChange(true)
    setPlaceOrderEnable(false)

    const maxCount = parseInt(productsDetails?.item_details?.quantity?.maximum?.count)

    setErrorMessage('') // Reset error message on each update

    setLocalQuantity((prevQuantity) => {
      let newQuantity = increment ? prevQuantity + 1 : prevQuantity - 1

      // Check if the new quantity exceeds the maximum count
      if (newQuantity > maxCount) {
        setErrorMessage('Cannot exceed maximum quantity')
        return prevQuantity // Keep the quantity unchanged
      } else {
        // Ensure quantity doesn't go below 1
        return Math.max(newQuantity, 1)
      }
    })
  }

  const isMaxQuantityReached = localQuantity >= parseInt(productsDetails?.quantity?.maximum?.count)

  function dispatchToast(type, message) {
    dispatch({
      type: toast_actions.ADD_TOAST,
      payload: {
        id: Math.floor(Math.random() * 100),
        type,
        message,
      },
    })
  }

  let selectedCustomizationIds = []

  const getCustomization_ = (groupId) => {
    let group = customization_state[groupId]
    if (!group) return

    group.selected.map((s) => selectedCustomizationIds.push(s.id))
    group?.childs?.map((child) => {
      getCustomization_(child)
    })
  }

  const getCustomizations = () => {
    if (!productsDetails?.customisation_items?.length) return null
    const customizations = []

    const firstGroupId = customization_state['firstGroup']?.id

    if (!firstGroupId) return
    getCustomization_(firstGroupId)

    for (const cId of selectedCustomizationIds) {
      let c = productsDetails?.customisation_items.find((item) => item.local_id === cId)
      if (c) {
        c = {
          ...c,
          quantity: {
            count: 1,
          },
        }
        customizations.push(c)
      }
    }

    return customizations
  }
  // let subtotal = productsDetails?.item_details?.price?.value
  const customisations = getCustomizations() ?? null
  let productPayload = {
    id: productsDetails?.id,
    local_id: productsDetails?.local_id,
    // bpp_id: productsDetails?.bpp_details?.bpp_id,
    // bpp_uri: productsDetails?.context?.bpp_uri,
    // domain: productsDetails?.context?.domain,
    // tags: productsDetails?.item_details?.tags,
    customisationState: customization_state,
    // contextCity: productsDetails?.context?.city,
    quantity: {
      count: localQuantity,
    },
    provider: {
      id: productsDetails?.provider_details?.id,
      local_id: productsDetails?.provider_details?.local_id,
      locations: productsDetails?.locations?.map((location) => ({
        id: location.id,
        local_id: location.local_id,
      })),
    },
    // product: {
    //   id: productsDetails?.id,
    //   subtotal,
    //   ...productsDetails?.item_details,
    // },
    customisations,
    hasCustomisations: !!customisations,
  }

  const getQuote = async (address, gps) => {
    responseRef.current = []

    try {
      setCheckoutLoading(true)

      let selectPayload = {
        context: {
          domain: productsDetails?.context?.domain,
          city: `${address?.areaCode ? address?.areaCode : getPinCode ? getPinCode : getCurrentPinCode}`,
        },
        message: {
          cart: {
            items: [productPayload],
          },
          fulfillments: [
            {
              end: {
                location: {
                  gps: gps,
                  address: {
                    area_code: `${address?.areaCode ? address?.areaCode : getPinCode ? getPinCode : getCurrentPinCode}`,
                  },
                },
              },
            },
          ],
        },
        userId: userId,
      }

      const data = await cancellablePromise(postCheckoutCall('/clientApis/v2/select', [selectPayload]))

      //Error handling workflow eg, NACK
      const isNACK = data.find((item) => item?.message?.ack?.status === 'NACK')
      if (isNACK) {
        setCheckoutLoading(false)
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message:
              'The Seller is not reachable to accept the orders. Please try again later. Thank you for being patience.',
          },
        })
        onClose()
      } else {
        // fetch through events

        // if (cartItems?.length > 0) {
        // const request_object = constructQouteObject(cartItems)
        // getProviderIds(request_object)
        // }
        onFetchQuote(
          data?.map((txn) => {
            const { context } = txn
            return context?.message_id
          }),
        )
      }
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.warning,
          message: 'The seller is not reachable at the moment. Please try again later. Thank you for your patience.',
        },
      })
      setCheckoutLoading(false)
      onClose()
      // history.push('/')
    }
  }

  function onFetchQuote(message_id) {
    eventTimeOutRef.current = []

    const token = getValueFromCookie('token')
    let header = {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
    }

    if (message_id) {
      message_id.forEach((id) => {
        let es = new window.EventSourcePolyfill(
          `${process.env.REACT_APP_BASE_URL}clientApis/events/v2?messageId=${id}`,
          header,
        )

        es.addEventListener('on_select', (e) => {
          const { messageId } = JSON.parse(e.data)
          onGetQuote(messageId)

          // Close the event connection as soon as the response is received
          es.close()
          const eventTimeout = eventTimeOutRef.current.find((item) => item.eventSource === es)
          if (eventTimeout) {
            clearTimeout(eventTimeout.timer)
            eventTimeOutRef.current = eventTimeOutRef.current.filter((item) => item.eventSource !== es)
          }
        })

        const timer = setTimeout(() => {
          eventTimeOutRef.current.forEach(({ eventSource, timer }) => {
            eventSource.close()
            clearTimeout(timer)
          })

          if (responseRef.current.length <= 0) {
            setCheckoutLoading(false)

            dispatch({
              type: toast_actions.ADD_TOAST,
              payload: {
                id: Math.floor(Math.random() * 100),
                type: toast_types.warning,
                message:
                  'The seller is not reachable at the moment. Please try again later. Thank you for your patience.',
              },
            })
            onClose()
            // history.replace('/')
            return
          }
        }, SSE_TIMEOUT)

        eventTimeOutRef.current = [
          ...eventTimeOutRef.current,
          {
            eventSource: es,
            timer,
          },
        ]
      })
    }
  }

  const onGetQuote = async (message_id) => {
    try {
      setCheckoutLoading(true)
      const data = await cancellablePromise(getSelectCall(`/clientApis/v2/on_select?messageIds=${message_id}`))
      responseRef.current = [...responseRef.current, data[0]]
      const transactionData = responseRef.current.map((item) => {
        return item?.context?.transaction_id
      })

      setTransactionId(transactionData)
      if (data[0]?.error) {
        // Close the loader
        setCheckoutLoading(false)

        const errMessage = getErrorMessageFromErrorCode(data[0]?.error?.code)
        // Display the error message in the toast notification
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: errMessage, // Use the error message from the API response
          },
        })

        // Return from the function or take any other necessary action
        return
      }

      data[0]?.message.quote.items.forEach((item) => {
        const findItemIndexFromCart = updatedCartItems.current.findIndex((prod) => prod.item.product.id === item.id)
        if (findItemIndexFromCart > -1) {
          updatedCartItems.current[findItemIndexFromCart].item.product.fulfillment_id = item.fulfillment_id
          updatedCartItems.current[findItemIndexFromCart].item.product.fulfillments = data[0].message.quote.fulfillments
        }
      })

      if (data[0]) {
        setCheckoutLoading(false)
        setPlaceOrderEnable(false)
        setOrderSummaryData(data[0]?.message?.quote?.quote?.breakup)
        setFullfillments(data[0]?.message?.quote?.fulfillments)
        setProductAmount(data[0]?.message?.quote?.quote?.price?.value)
        setSelectedItemId(data[0]?.message?.quote?.items)
      }

      localStorage.setItem('updatedCartItems', JSON.stringify(responseRef.current))
      // const filterFulfillments = (items, fulfillments) => {
      //   return items
      //     .map((item) => {
      //       return fulfillments?.filter((fulfillment) => item.fulfillment_id === fulfillment.id)
      //     })
      //     .flat()
      // }

      // if (selectedItemId.length > 0) {
      //   const filteredItems = filterFulfillments(data[0]?.message?.quote?.items, data[0]?.message?.quote?.fulfillments)
      //   setFilteredFullfilments(filteredItems)
      // }
    } catch (err) {
      setCheckoutLoading(false)
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err.message,
        },
      })
      onClose()
    }
  }

  useEffect(() => {
    if (fullfillments?.length === 1) {
      initializeOrder(fullfillments[0])
    }
  }, [fullfillments])

  // useEffect(() => {
  //   if (filteredFulfillments?.length === 1) {
  //     initializeOrder(filteredFulfillments[0])
  //   }
  // }, [filteredFulfillments])

  const removeNullValues = (object) => {
    Object.entries(object).forEach(([k, v]) => {
      if (v && typeof v === 'object') removeNullValues(v)
      if ((v && typeof v === 'object' && !Object.keys(v).length) || v === null || v === undefined || v.length === 0) {
        if (Array.isArray(object)) object.splice(k, 1)
        else if (!(v instanceof Date)) delete object[k]
      }
    })
    return object
  }

  const initializeOrder = async (fillmentData) => {
    const deviceId = await getOrCreateDeviceId()
    try {
      responseRef.current = []
      setInitializeOrderLoading(true)
      const shippingAddress = JSON.parse(getValueFromCookie('delivery_address'))
      let intializeItemPayload = {
        id: productsDetails?.id,
        local_id: productsDetails?.local_id,
        // bpp_id: productsDetails?.bpp_details?.bpp_id,
        // bpp_uri: productsDetails?.context?.bpp_uri,
        // domain: productsDetails?.context?.domain,
        tags: productsDetails?.item_details?.tags,
        // contextCity: productsDetails?.context?.city,
        fulfillment_id: fillmentData?.id,
        // ipAddress: '::ffff:192.168.16.10', // TODO
        quantity: {
          count: localQuantity,
        },
        provider: {
          id: productsDetails?.provider_details?.id,
          local_id: productsDetails?.provider_details?.local_id,
          locations: productsDetails?.locations?.map((location) => ({
            id: location.id,
            local_id: location.local_id,
          })),
        },
        // provider: {
        //   id: productsDetails?.bpp_details?.bpp_id,
        //   locations: [productsDetails?.location_details],
        //   ...productsDetails?.provider_details,
        // },
        // product: {
        //   id: productsDetails?.id,
        //   subtotal,
        //   ...productsDetails?.item_details,
        // },
        customisations,
        hasCustomisations: !!customisations,
        userId: userId,
      }

      let initializePayload = {
        context: {
          transaction_id: transactioId[0],
          domain: productsDetails?.context?.domain,
          city: shippingAddress?.address.areaCode,
          // state: shippingAddress?.address.state,
          // pincode: shippingAddress?.address.areaCode,
        },
        message: {
          items: [intializeItemPayload],
          // fulfillments: [fillmentData],
          billing_info: {
            address: removeNullValues(shippingAddress?.address),
            phone: shippingAddress?.descriptor?.phone,
            name: shippingAddress?.address?.name,
            email: shippingAddress?.descriptor?.email,
          },
          delivery_info: {
            type: 'Delivery',
            phone: shippingAddress?.descriptor?.phone,
            name: shippingAddress?.address?.name,
            email: shippingAddress?.descriptor?.email,
            location: {
              gps: shippingAddress.gps,
              address: shippingAddress?.address,
            },
          },
          payment: {
            type: 'ON-ORDER',
          },
        },
        deviceId: deviceId,
      }
      const data = await cancellablePromise(postCheckoutCall('/clientApis/v2/initialize_order', [initializePayload]))
      //Error handling workflow eg, NACK
      const isNACK = data.find((item) => item.error && item.message.ack.status === 'NACK')
      if (isNACK) {
        setInitializeOrderLoading(false)
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message:
              'The Seller is not reachable to accept the orders. Please try again later. Thank you for being patience.',
          },
        })
        onClose()
      } else {
        const parentTransactionIdMap = new Map()
        data.map((data) => {
          const provider_id = data?.context?.provider_id
          return parentTransactionIdMap.set(provider_id, {
            parent_order_id: data?.context?.parent_order_id,
            transaction_id: data?.context?.transaction_id,
          })
        })

        // store parent order id to cookies
        AddCookie('parent_order_id', data[0]?.context?.parent_order_id)
        // store the map into cookies
        AddCookie('parent_and_transaction_id_map', JSON.stringify(Array.from(parentTransactionIdMap.entries())))
        onInit(
          data?.map((txn) => {
            const { context } = txn
            return context?.message_id
          }),
        )
      }
    } catch (err) {
      setInitializeOrderLoading(false)
      dispatchToast(toast_types.error, err?.response?.data?.message)
      onClose()
      // history.push('/')
    }
  }

  // use this function to initialize the order

  function onInit(message_id) {
    setInitializeOrderLoading(true)
    eventTimeOutRef.current = []
    const token = getValueFromCookie('token')
    let header = {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
    }

    message_id.forEach((id) => {
      let es = new window.EventSourcePolyfill(
        `${process.env.REACT_APP_BASE_URL}clientApis/events/v2?messageId=${id}`,
        header,
      )

      es.addEventListener('on_init', (e) => {
        const { messageId } = JSON.parse(e.data)
        onInitializeOrder(messageId)

        // Close the event connection as soon as the response is received
        es.close()
        const eventTimeout = eventTimeOutRef.current.find((item) => item.eventSource === es)
        if (eventTimeout) {
          clearTimeout(eventTimeout.timer)
          eventTimeOutRef.current = eventTimeOutRef.current.filter((item) => item.eventSource !== es)
        }

        setInitializeOrderLoading(false)
      })

      const timer = setTimeout(() => {
        eventTimeOutRef.current.forEach(({ eventSource, timer }) => {
          eventSource.close()
          clearTimeout(timer)
        })

        // check if all the orders got canceled
        if (responseRef.current.length <= 0) {
          setInitializeOrderLoading(false)
          dispatchToast(
            toast_types.error,
            'The seller is not reachable at the moment. Please try again later. Thank you for your patience.',
          )
          onClose()
          // history.push('/')
          return
        }
      }, SSE_TIMEOUT)

      eventTimeOutRef.current = [
        ...eventTimeOutRef.current,
        {
          eventSource: es,
          timer,
        },
      ]
    })
  }

  const onInitializeOrder = async (message_id) => {
    setInitializeOrderLoading(true)
    try {
      const data = await cancellablePromise(
        getSelectCall(`/clientApis/v2/on_initialize_order?messageIds=${message_id}`),
      )

      setOrderSummaryData(data[0]?.message?.order?.quote?.breakup)
      setProductAmount(data[0]?.message?.order?.quote?.price?.value)
      responseRef.current = [...responseRef.current, data[0]]

      let checkoutObj = {
        successOrderIds: [],
        productQuotes: [],
      }

      responseRef.current.forEach((item) => {
        const { message } = item
        checkoutObj = {
          payment: message?.order?.payment,
          productQuotes: [...checkoutObj.productQuotes, message?.order?.quote],
          successOrderIds: [...checkoutObj.successOrderIds, message?.order?.provider?.id.toString()],
        }
      })
      // AddCookie("checkout_details", JSON.stringify(checkoutObj));
      localStorage.setItem('checkout_details', JSON.stringify(checkoutObj))
      // let oldData = updatedCartItems.current
      // oldData[0].message.quote.quote = data[0].message.order.quote
      // oldData[0].message.quote.payment = data[0].message.order.payment

      if (data[0]) {
        if (selectedItemId[0]?.id === data[0]?.message?.order?.items[0]?.id) {
          setInitializeOrderLoading(false)
          setPlaceOrderEnable(true)
        } else {
          dispatchToast(toast_types.error, 'You cannot with selected items due to unavailability.')
          setInitializeOrderLoading(false)
          onClose()
          return
        }
      }
    } catch (err) {
      dispatchToast(toast_types.error, err?.response?.data?.error?.message)
      setInitializeOrderLoading(false)
      setPlaceOrderEnable(false)
      onClose()
      history.push('/')
    }
  }

  const handleRadioClick = (fillmentData) => {
    initializeOrder(fillmentData)
    setSelectedFulfillment(fillmentData)
  }

  const deleteAddressItem = async (itemId) => {
    try {
      const url = `/clientApis/v1/delete_delivery_address/${itemId}`
      await deleteWithAuthentication(url)
      fetchDeliveryAddress()
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: 'Address has bee deleted successfully!',
        },
      })
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error?.response?.data?.error?.message,
        },
      })
    }
  }

  const selectAddress = (id) => {
    setSelectedAddressId(id)
  }

  return (
    <OrderModalWrapper>
      <ProductDetailWrapper>
        <ProductImageWrapper>
          <img
            src={
              productsDetails?.item_details?.descriptor?.symbol
                ? productsDetails?.item_details?.descriptor?.symbol
                : product?.item_details?.descriptor?.symbol
                  ? product?.item_details?.descriptor?.symbol
                  : ProductImage
            }
            alt={
              productsDetails?.item_details?.descriptor?.name
                ? productsDetails?.item_details?.descriptor?.name
                : product?.item_details?.descriptor?.name
            }
            onError={(e) => {
              e.target.src =
                'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
            }}
          />
        </ProductImageWrapper>

        <ProductDetailContainer>
          <ProductName>
            {productsDetails?.item_details?.descriptor?.name
              ? productsDetails?.item_details?.descriptor?.name
              : product?.item_details?.descriptor?.name
                ? product?.item_details?.descriptor?.name
                : 'Product Name'}
          </ProductName>
          <ProductPricingSection>
            <ProductPrice>
              {`₹${formatIndianRupees(
                Number.isInteger(Number(productsDetails?.item_details?.price?.value))
                  ? Number(productsDetails?.item_details?.price?.value).toFixed(2)
                  : Number(formattedCurrentProductValue).toFixed(2),
              )}`}
              <OriginalPrice>
                {maxProductValue > currentProductValue && (
                  <OriginalPrice>₹{formatIndianRupees(formattedMaxProductValue)}</OriginalPrice>
                )}
              </OriginalPrice>
            </ProductPrice>
            <div>
              <VariationsRenderer
                productPayload={productsDetails}
                variationState={variationState}
                setVariationState={setVariationState}
                buyNow={buyNow}
                setVariantId={setVariantId}
                setUomProductId={setUomProductId}
                // chartImage={productPayload?.attributes?.size_chart || ''}
                // isFashion={productPayload?.context?.domain === 'ONDC:RET12'}
              />
            </div>

            <QuantityWrapper>
              <QuantityOption onClick={() => updateQuantity(false)}>-</QuantityOption>
              <ProductQuantity>{localQuantity}</ProductQuantity>
              <QuantityOption onClick={() => updateQuantity(true)} disabled={isMaxQuantityReached}>
                +
              </QuantityOption>
            </QuantityWrapper>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            {serviceable && <div style={{ color: 'red' }}>{serviceable}</div>}
            <CustomizationRenderer
              customization_state={customization_state}
              setCustomizationState={setCustomizationState}
            />
          </ProductPricingSection>
        </ProductDetailContainer>
      </ProductDetailWrapper>
      <InformationSection>
        <CartSummaryWrapper>
          <CartSummaryTitle>Order Summary</CartSummaryTitle>
          <CartDetailContainer>
            <div>
              <CartDetailWrapper>
                <CartDetailTitle>
                  {productsDetails?.item_details?.descriptor?.name
                    ? productsDetails?.item_details?.descriptor?.name
                    : // ? productsDetails?.item_details?.descriptor? .name
                      product?.item_details?.descriptor?.name
                      ? product?.item_details?.descriptor?.name
                      : ProductImage}
                </CartDetailTitle>
                <CartDetailContent>
                  {`₹${formatIndianRupees(
                    (
                      parseFloat(
                        Number.isInteger(Number(productsDetails?.item_details?.price?.value))
                          ? Number(productsDetails?.item_details?.price?.value)
                          : Number(product?.item_details?.price?.value),
                      ) * localQuantity
                    ).toFixed(2),
                  )}`}
                </CartDetailContent>
              </CartDetailWrapper>
              <CartDetailWrapper>
                <CartDetailTitle>Delivery charges</CartDetailTitle>
                <CartDetailContent>
                  {!orderSummaryData
                    ? '-'
                    : // ? '₹0.00'
                      `₹${(orderSummaryData.find((item) => item.title.toLowerCase() === 'delivery charges') || {}).price?.value || '0.00'}`}
                </CartDetailContent>
              </CartDetailWrapper>
              <CartDetailWrapper>
                <CartDetailTitle>Tax</CartDetailTitle>
                <CartDetailContent>
                  {!orderSummaryData
                    ? '-'
                    : `₹${orderSummaryData.find((item) => item.title === 'Tax')?.price.value || '0.00'}`}
                </CartDetailContent>
              </CartDetailWrapper>
              <CartDetailWrapper>
                <CartDetailTitle>Packing charges</CartDetailTitle>
                <CartDetailContent>
                  {!orderSummaryData
                    ? '-'
                    : `₹${orderSummaryData.find((item) => item.title === 'Packing charges')?.price.value || '0.00'}`}
                </CartDetailContent>
              </CartDetailWrapper>
              <CartDetailWrapper>
                <CartDetailTitle>Convenience Fee</CartDetailTitle>
                <CartDetailContent>
                  {!orderSummaryData
                    ? '-'
                    : `₹${orderSummaryData.find((item) => item.title === 'Convenience Fee')?.price.value || '0.00'}`}
                </CartDetailContent>
              </CartDetailWrapper>

              <PaymentModeWrapper>
                <PaymentDetailContent>Discount</PaymentDetailContent>
                <PaymentType>
                  {!orderSummaryData
                    ? '-'
                    : `
              ₹${Math.abs(orderSummaryData.find((item) => item.title === 'discount')?.price.value || '0.00')}

                    `}
                </PaymentType>
              </PaymentModeWrapper>
              <PaymentModeWrapper>
                <PaymentDetailContent>Payment Mode</PaymentDetailContent>
                <PaymentType>Prepaid</PaymentType>
              </PaymentModeWrapper>
              <OrderTotalWrapper>
                <TotalOrderContent>Order Total</TotalOrderContent>
                <TotalOrderContent>
                  {!orderSummaryData
                    ? `₹${formatIndianRupees(
                        parseFloat(
                          Number.isInteger(Number(productsDetails?.item_details?.price?.value))
                            ? Number(productsDetails?.item_details?.price?.value) * localQuantity
                            : Number(product?.item_details?.price?.value) * localQuantity,
                        ).toFixed(2),
                      )}`
                    : `₹${orderSummaryData
                        .reduce((total, item) => total + parseFloat(item.price.value), 0)
                        .toFixed(2)}`}
                </TotalOrderContent>
              </OrderTotalWrapper>
            </div>
          </CartDetailContainer>
        </CartSummaryWrapper>
        <DeliveryAddressWrapper>
          <DeliveryAddressTitle>Delivery Address</DeliveryAddressTitle>
          {!addressList || addressList.length === 0 || addressList.every((item) => !item.address) ? (
            <DeliverAddressContainer>
              <NoAddressIcon />
              <AddressText>No Saved Address</AddressText>
            </DeliverAddressContainer>
          ) : (
            addressList?.length && (
              <AddressListWrapper>
                <RadioGroup className="radio-group">
                  {addressList?.map((item, index) => {
                    const { id, address } = item
                    let isSelected = selectedAddressId === id
                    if (quantityChange) isSelected = false

                    if (!address) {
                      return null
                    }

                    return (
                      <AddressListContainer key={index}>
                        <AddressDetailSection>
                          <FormControlLabel
                            className="form-controller"
                            control={
                              <Radio
                                checked={isSelected}
                                className="radio-button"
                                onClick={() => {
                                  if (!isSelected) {
                                    selectAddress(id)
                                    AddCookie('delivery_address', JSON.stringify(item))
                                    getQuote(address, item?.gps)
                                    setQuantityChange(false)
                                  }
                                }}
                              />
                            }
                          />
                          <InfoDetailSection>
                            <UserName>{address?.name}</UserName>
                            <AddressDetail>{`${address?.building}, ${address?.locality}, ${address?.state}`}</AddressDetail>
                          </InfoDetailSection>
                          <Pincode>{address?.areaCode}</Pincode>
                        </AddressDetailSection>
                        <IconWrapper>
                          <DeleteLogo onClick={() => deleteAddressItem(id)} />
                        </IconWrapper>
                      </AddressListContainer>
                    )
                  })}
                </RadioGroup>
              </AddressListWrapper>
            )
          )}

          {addressList?.length > 0 && fullfillments && (
            <DeliveryMode>
              <DeliveryAddressTitle>Delivery Mode</DeliveryAddressTitle>
              {initializeOrderLoading && <LinearProgress />}
              <RadioGroup className="radio-group">
                {fullfillments?.map((fillmentData) => {
                  let isChecked = fullfillments.length === 1 || selectedFulfillment === fillmentData
                  if (quantityChange) isChecked = false

                  return (
                    <React.Fragment key={fillmentData?.id}>
                      <DeliveryModeContainer>
                        <FormControlLabel
                          className="form-controller"
                          control={<Radio className="radio-button" />}
                          onClick={() => handleRadioClick(fillmentData)}
                          checked={isChecked}
                        />
                        <AddressDetail>{fillmentData['@ondc/org/category']}</AddressDetail>
                      </DeliveryModeContainer>
                    </React.Fragment>
                  )
                })}
              </RadioGroup>
            </DeliveryMode>
          )}
          <NewAddress onClick={() => setOpenForm(true)}>+ Add New Address</NewAddress>
        </DeliveryAddressWrapper>
      </InformationSection>

      {openForm && <NewAddressForm setOpenForm={setOpenForm} />}
      <PlaceOrder
        product={productsDetails}
        productAmount={productAmount}
        placeOrderEnable={placeOrderEnable}
        onClose={onClose}
      />
      {checkoutLoading && (
        <LoaderWrapper>
          <Loader src={loader} alt="loader" />
        </LoaderWrapper>
      )}
    </OrderModalWrapper>
  )
}

export default PlaceOrderModal
