import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Slider from 'react-slick'
import Widget1 from '../../assets/images/dashboardProducts/B_1.png'
import Widget2 from '../../assets/images/dashboardProducts/B_2.png'
import Widget3 from '../../assets/images/dashboardProducts/B_3.png'
import MobileWidget1 from '../../assets/images/dashboardProducts/Widget1.png'
import MobileWidget2 from '../../assets/images/dashboardProducts/Widget2.png'
import MobileWidget3 from '../../assets/images/dashboardProducts/Widget3.png'

import DiwaliBanner from 'assets/images/dashboardProducts/Banner_final.png'
import MobileDiwaliBanner from 'assets/images/dashboardProducts/banner-mob--.png'
import TopBrands from './topBrands/topBrands'
import { useThemeContext } from '../../context/themeContext'
import TopSellingProducts from '../../views/TopSelling'
import {
  HomePageContainer,
  NewDashboardWrapper,
  FirstSection,
  SecondSection,
  ThirdSection,
  MobileDashboardWrapper,
  NewMobileDashboardWrapper,
  BackgroundImageSection,
  OfferBannerWrapper,
  MobileOfferBannerWrapper,
} from '../../styles/home'
import { useTheme } from '../../context/newThemeContext'
import { AddCookie, getValueFromCookie } from '../../utils/cookies'
import { search_types } from '../../constants/searchTypes'
import { SearchContext } from '../../context/searchContext'
import ModalComponent from '../common/Modal'
import PlaceOrderModal from '../../views/OrderModal'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Banner1 from 'assets/images/dashboardProducts/Banner_1.png'
import Banner2 from 'assets/images/dashboardProducts/Banner_2.png'
import BannerMobile1 from 'assets/images/dashboardProducts/Banner-1-mob.png'
import BannerMobile2 from 'assets/images/dashboardProducts/Banner-2-mob.png'
import { BannerWrapper, BannerItem, IconButton } from 'styles/home'
import PreviousArrow from 'assets/svg/RightArrowDark'
import NextArrow from 'assets/svg/LeftArrowDark'

const Home = () => {
  const history = useHistory()
  const locationData = useLocation()
  const { setSearchData } = useContext(SearchContext)
  const { mode } = useThemeContext()
  const { theme } = useTheme()
  const [orderModal, setOrderModal] = useState(false)

  const useQuery = () => {
    const { search } = locationData
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQuery()

  const [searchedLocation, setSearchedLocation] = useState({
    name: '',
    lat: '',
    lng: '',
  })

  const banners = [
    { type: 'Earphone', src: Banner1, mobile: BannerMobile1, alt: 'Best Headphones' },
    { type: 'Earphone', src: Banner2, mobile: BannerMobile2, alt: 'Premium Quality Earphones' },
  ]

  useEffect(() => {
    let search_context = getValueFromCookie('search_context')
    setSearchedLocation(search_context?.location)
  }, [])

  const handleclicksearch = (value) => {
    let searchDataUpdate = {
      type: search_types.PRODUCT,
      value: value.replace('.', ''),
    }

    setSearchData(searchDataUpdate)
    const search_context = {
      search: searchDataUpdate,
      location: searchedLocation,
    }
    AddCookie('search_context', JSON.stringify(search_context))

    updateSearchParams(searchDataUpdate)
  }

  const updateSearchParams = (searchDataUpdate) => {
    const categoryName = query.get('c')
    const subCategoryName = query.get('sc')
    const params = new URLSearchParams({})

    if (locationData.pathname !== '/products') {
      history.push(`/products?s=${searchDataUpdate.value}`)
    } else {
      if (searchDataUpdate.value) {
        params.set('s', searchDataUpdate.value)
      }

      if (categoryName) {
        params.set('c', categoryName)
      }

      if (subCategoryName) {
        params.set('sc', subCategoryName)
      }

      history.replace({ pathname: locationData.pathname, search: params.toString(), state: searchDataUpdate.value })
    }
  }

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 1000,

    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  }

  const bannerSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 1000,

    nextArrow: (
      <IconButton>
        <PreviousArrow />
      </IconButton>
    ),
    prevArrow: (
      <IconButton>
        <NextArrow />
      </IconButton>
    ),
  }

  const handleRedirect = () => {
    history.push(`/providers`)
  }

  return (
    <>
      <HomePageContainer color={mode} theme={theme}>
        <BannerWrapper>
          <Slider {...bannerSettings}>
            {banners.map((banner, index) => (
              <BannerItem key={index} onClick={() => handleclicksearch(banner?.type)}>
                <img src={banner.src} alt={banner.alt} />
              </BannerItem>
            ))}
          </Slider>
        </BannerWrapper>
        <MobileDashboardWrapper>
          <Slider {...settings}>
            <BackgroundImageSection onClick={() => handleclicksearch('Earphone')}>
              <img
                src={BannerMobile1}
                alt="Best Headphones"
                onError={(e) => {
                  e.target.src =
                    'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                }}
              />
            </BackgroundImageSection>
            <BackgroundImageSection onClick={() => handleclicksearch('Earphone')}>
              <img
                src={BannerMobile2}
                alt="Premium Quality Earphones"
                onError={(e) => {
                  e.target.src =
                    'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                }}
              />
            </BackgroundImageSection>
          </Slider>
        </MobileDashboardWrapper>
        <OfferBannerWrapper onClick={handleRedirect}>
          <img
            src={DiwaliBanner}
            alt="Best Electronic Items"
            onError={(e) => {
              e.target.src =
                'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
            }}
          />
        </OfferBannerWrapper>
        <MobileOfferBannerWrapper onClick={handleRedirect}>
          <img
            src={MobileDiwaliBanner}
            alt="Best Electronic Items"
            onError={(e) => {
              e.target.src =
                'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
            }}
          />
        </MobileOfferBannerWrapper>
        <TopBrands />

        <NewDashboardWrapper theme={theme}>
          <FirstSection onClick={() => handleclicksearch(`Earphone`)}>
            <img
              src={Widget1}
              alt="Best place to buy premium headphones"
              onError={(e) => {
                e.target.src =
                  'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
              }}
            />
          </FirstSection>
          <SecondSection onClick={() => handleclicksearch(`True Wireless Stereo `)}>
            <img
              src={Widget2}
              alt="Best place to buy Premium"
              onError={(e) => {
                e.target.src =
                  'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
              }}
            />
          </SecondSection>
          <ThirdSection onClick={() => handleclicksearch(`Earphone`)}>
            <img
              src={Widget3}
              alt="Best place to buy Stylish headphones"
              onError={(e) => {
                e.target.src =
                  'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
              }}
            />
          </ThirdSection>
        </NewDashboardWrapper>
        <NewMobileDashboardWrapper>
          <Slider {...settings}>
            <BackgroundImageSection onClick={() => handleclicksearch(`Earphone`)}>
              <img
                src={MobileWidget1}
                alt="Best place to buy premium headphones"
                onError={(e) => {
                  e.target.src =
                    'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                }}
              />
            </BackgroundImageSection>
            <BackgroundImageSection onClick={() => handleclicksearch('True Wireless Stereo')}>
              <img
                src={MobileWidget2}
                alt="Best place to buy Premium Airpods"
                onError={(e) => {
                  e.target.src =
                    'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                }}
              />
            </BackgroundImageSection>
            <BackgroundImageSection onClick={() => handleclicksearch(`Earphone`)}>
              <img
                src={MobileWidget3}
                alt="Best place to buy Stylish headphones"
                onError={(e) => {
                  e.target.src =
                    'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                }}
              />
            </BackgroundImageSection>
          </Slider>
        </NewMobileDashboardWrapper>

        {/* <ElectronicBrands /> */}
        <TopSellingProducts />
      </HomePageContainer>
      {orderModal && (
        <ModalComponent
          open={orderModal}
          onClose={() => setOrderModal(false)}
          title="Get Ready To Shop !"
          titleBg={true}
        >
          <PlaceOrderModal onClose={() => setOrderModal(false)} />
        </ModalComponent>
      )}
    </>
  )
}

export default Home
