import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import Cookies from 'js-cookie'
import { theme } from 'styles/Theme/theme'
import BharathamLogo from 'assets/images/BharathamLogo.png'

const RazorpayPayment = ({ onApiSuccess, orderId, amount, setRazorpayPaymentId }) => {
  const productName = JSON.parse(localStorage.getItem('productNames'))?.join(', ') || ''
  const order_id = localStorage.getItem('orderId') || ''
  const phone = Cookies.get('phone')
  const data = phone ? JSON.parse(phone) : null
  let userName
  let userEmail
  let userPhone
  const userCookie = Cookies.get('user')
  if (userCookie) {
    const user = JSON.parse(userCookie)
    userName = user?.name
    userEmail = user?.email
    userPhone = user?.phone
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }

      script.onerror = () => {
        resolve(false)
      }

      document.body.appendChild(script)
    })
  }

  async function displayRazorpay() {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }

    const options = {
      key: process.env.RAZORPAY_KEY_ID,
      amount: amount,
      currency: 'INR',
      name: 'Bharatham',
      description: productName,
      image: BharathamLogo,
      order_id: orderId,
      config: {
        display: {
          blocks: {
            utib: {
              instruments: [
                {
                  method: 'upi',
                  apps: ['google_pay', 'phonepe'],
                  flows: ['qr'],
                },
              ],
            },
          },
          sequence: ['block.utib', 'block.other'],
          preferences: {
            show_default_blocks: true, // Should Checkout show its default blocks?
          },
        },
      },

      handler: (res) => {
        if (res?.razorpay_payment_id) {
          setRazorpayPaymentId(res?.razorpay_payment_id)

          ReactGA.event('purchase', {
            transaction_id: res.razorpay_payment_id,
            value: amount,
            currency: 'INR',
            items: [
              {
                item_name: productName, // Custom product/service name
                price: amount,
              },
            ],
          })
        }
      },

      prefill: {
        name: userName,
        email: userEmail,
        contact: data?.phone || userPhone,
      },
      notes: {
        address: order_id,
      },
      theme: {
        color: `${theme.PRIMARYCOLOR}`,
      },
    }

    const paymentObject = new window.Razorpay(options)
    paymentObject.open()
  }

  useEffect(() => {
    if (onApiSuccess) {
      displayRazorpay()
    }
  }, [onApiSuccess])

  return null
}

export default RazorpayPayment
