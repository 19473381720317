import React from 'react'
import FooterApi from 'utils/FooterApi'
import BreadcrumbComponent from 'components/Breadcrumbs'
import { MainWrapper, AboutUsWrapper, ContentWrapper } from 'styles/FooterLinks/AboutUsPage'

const CancellationPolicy = () => {
  const { data } = FooterApi('privacypolicy')
  return (
    <MainWrapper>
      <AboutUsWrapper>
        <BreadcrumbComponent title="Privacy Policy" />
        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: data?.privacypolicy }} />
        </ContentWrapper>
      </AboutUsWrapper>
    </MainWrapper>
  )
}

export default CancellationPolicy
